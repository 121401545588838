<template>
	<main>
		<HeaderTab
			:title="$t('monte.localisation_semence')"
			:swiper_tabs="swiper_tabs"
		/>

		<div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div>
                            <router-view></router-view>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</main>
</template>

<script>

export default {
	name: 'LocalisationSemence',
	computed:{
		swiper_tabs() {
			return [{
	            id: 'gestion_cuves',
				label: 'monte.congelation.gestion_cuves',
				active: false,
	            href : 'gestionCuves'
			},
			{
	            id: 'dilueurs',
				label: 'monte.congelation.gestion_dilueurs',
				active: false,
	            href : 'dilueurs',
	            hidden: !this.hasCongelAccess
			},
			{
	            id: 'protocoles',
				label: 'monte.congelation.gestion_protocoles',
				active: false,
	            href : 'protocoles',
	            hidden: !this.hasCongelAccess
			}]
		},
        hasCongelAccess() {
            return this.$store.state.userAccess.hasCongelAccess
        },
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
	}
};
</script>
